import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import cx from 'classnames'
import styles from './index.module.scss'
type Iprops = {
    data: {
        title: string;
        children: {
            title: string;
            route: string;
            disabled?: boolean
        }[]
    }
}
const SingleNav = ({ data }: Iprops) => {
    const [showSubMenu, setShowSubMenu] = useState(false);
    const dropdownBtnRef = useRef(null)
    const timer = useRef(null)

    useEffect(() => {
        const handler = (e) => {
            if (window.isMobile) {
                return;
            }
            const delayHide = () => {
                if (timer.current) {
                    clearTimeout(timer.current)
                }
                timer.current = setTimeout(() => {
                    setShowSubMenu(false)
                }, 50)
            }
            if (dropdownBtnRef.current.contains(e.target)) {
                setShowSubMenu(true)
                if (timer.current) {
                    clearTimeout(timer.current)
                }
            } else {
                delayHide()
            }
        }
        document.addEventListener('mouseover', handler)
        return () => {
            document.removeEventListener('mouseover', handler)
        }
    }, [])

    const subMenu = <div className={cx(styles.subMenu, { [styles.show]: showSubMenu })}>
        {
            data.children.map(v => v.disabled ? <span style={{ color: 'gray' }} className={cx(styles.smItem)}>{v.title}</span> : (v.route.startsWith('http') ?
                <a className={cx(styles.smItem)} href={v.route} onClick={() => setShowSubMenu(false)} target="_blank">{v.title}</a> :
                <Link key={v.route} to={v.route} className={cx(styles.smItem)} onClick={() => setShowSubMenu(false)}>{v.title}</Link>)
            )
        }
    </div>

    return (
        <div ref={dropdownBtnRef} className={cx(styles.item, styles.dropdown)}>
            <div className={cx(styles.dropdownBtn, 'hover-item')}>
                <span>{data.title}</span>
                <img style={showSubMenu ? { transform: 'rotateZ(180deg)' } : {}} src="/images/downArrow.svg" alt="" />
            </div>
            {subMenu}
        </div>
    );
}

export default SingleNav;