import styles from './index.module.scss'
import cx from 'classnames'

function About() {
  return <div className={styles.page}>
    {
      window.isMobile && <div className={styles.pTitle}>About</div>
    }
    <div className={styles.section1}>
      {/* <img className={styles.bg} src="/images/aboutBg.png" alt="" /> */}
      <iframe className={styles.iframe} width="640" height="360" src="https://www.youtube.com/embed/QxFVJ-k2xHc?si=WxdL5zemAJvqmA6l&autoplay=1&rel=0&controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen ></iframe>

      <div className={styles.textWrap}>
        <div data-aos="fade-left">
          <div className={styles.row}><b>DIN</b></div>
          <div className={styles.row}>is designed to provide infra for</div>
          <div className={styles.row}><b>AI Agents</b> & <b>AI dApps</b></div>
        </div>

      </div>
    </div>
    <div className={styles.section2}>
      <div className={styles.title}>
        <span className={styles.splitL}></span>
        <span className={styles.text}>Backed By</span>
        <span className={styles.splitR}></span>
      </div>
      {window.isMobile ?
        <div className={styles.icons}>
          <img src="/images/iconGroup.png" alt="" />

        </div> :
        <div className={styles.icons}>
          <div className={styles.scrollX}>
            <div className={styles.move}>
              <img src="/images/b1.png" alt="" />
              <img src="/images/b2.png" alt="" />
              <img src="/images/b3.png" alt="" />
              <img src="/images/b4.png" alt="" />
              <img src="/images/b5.png" alt="" />
              <img src="/images/b6.png" alt="" />
              <img src="/images/b7.png" alt="" />
              <img src="/images/b8.png" alt="" />
              <img src="/images/b9.png" alt="" />
              <img src="/images/b10.png" alt="" />
              <img src="/images/b11.png" alt="" />
              <img src="/images/b12.png" alt="" />
              <img src="/images/b13.png" alt="" />
            </div>
            <div className={styles.move}>
              <img src="/images/b1.png" alt="" />
              <img src="/images/b2.png" alt="" />
              <img src="/images/b3.png" alt="" />
              <img src="/images/b4.png" alt="" />
              <img src="/images/b5.png" alt="" />
              <img src="/images/b6.png" alt="" />
              <img src="/images/b7.png" alt="" />
              <img src="/images/b8.png" alt="" />
              <img src="/images/b9.png" alt="" />
              <img src="/images/b10.png" alt="" />
              <img src="/images/b11.png" alt="" />
              <img src="/images/b12.png" alt="" />
              <img src="/images/b13.png" alt="" />
            </div>
          </div>
        </div>}

    </div>
    <div className={styles.section3}>
      <div className={styles.title}>
        How DIN works
      </div>
      <div className={styles.content}>
        {
          window.isMobile ?
            <img src="/images/workflowM.png" alt="" />
            :
            <img src="/images/workflow.png" alt="" />
        }

      </div>
    </div>

  </div >
}

export default About;
