import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import cx from 'classnames'
import styles from './index.module.scss'
type Iprops = {
    data: {
        title: string;
        children: {
            title: string;
            route: string;
            disabled?: boolean
        }[]
    },
    setDrawerVisible: (value: boolean) => void
}
const SingleNav = ({ data, setDrawerVisible }: Iprops) => {
    const [showSubMenu, setShowSubMenu] = useState(false);

    return (
        <div className={cx(styles.item, styles.mDropdown)}>
            <div className={styles.mDropdownBtn} onClick={() => setShowSubMenu(!showSubMenu)}>
                <span>{data.title}</span>
                <img style={showSubMenu ? { transform: 'rotateZ(180deg)' } : {}} src="/images/downArrow.svg" alt="" />
            </div>
            {
                showSubMenu &&
                <div className={styles.mSubMenu}>
                    {
                        data.children.map(v => v.disabled ? <span style={{ color: 'gray' }} className={cx(styles.smItem)}>{v.title}</span> : (v.route.startsWith('http') ?
                            <a className={cx(styles.smItem)} href={v.route} onClick={() => {
                                setDrawerVisible(false)
                                setShowSubMenu(false)
                            }} target="_blank">{v.title}</a> :
                            <Link key={v.route} to={v.route} className={cx(styles.smItem)} onClick={() => {
                                setDrawerVisible(false)
                                setShowSubMenu(false)
                            }}>{v.title}</Link>))
                    }
                </div>
            }
        </div>
    );
}

export default SingleNav;